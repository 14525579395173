<template>
  <div class="page-wrap introduce">
    <div class="banner-wrap">
      <div class="text">
        <span>方</span><span>效</span><span>概</span><span>况</span>
      </div>
    </div>
    <div class="content-wrap">
      <div class="box">
        <div class="title">
          <p>事在四方 四方来效</p>
          <p>效秉承“事在四方，四方来效”的执业理念，致力于为国内外客户提供优质的法律服务</p>
        </div>
        <div class="content">
          <p>广东方效律师事务所（以下简称“方效”）经广东省司法厅核准成立，位于广东省广州市天河区珠江东路6号周大福金融中心41层，拥有面积近1800平的办公场所。自成立以来，方效秉承“事在四方，四方来效”的执业理念，致力于为国内外客户提供优质的法律服务，志在汇集律界菁英、凝聚一流人才，建立一家专业化、品牌化、国际化、规模化发展的大型综合性律师事务所。 </p>
          <p>目前，方效已为多家国内企业和个人提供了优质的法律服务，并获得了客户的一致信赖及认可。未来，方效将会继续秉承“专业、高效、诚信、勤勉”的执业宗旨，以专业化、团队化发展建设为根本，推动方效的发展走向新的篇章。 </p>
          <p>方效自成立以来，不断推陈出新，精进律所运营战略以及组织架构，加强所内律师执业风险管理，规范服务体系，力求为客户提供全面、高效且优质的法律服务体验，为广州律师行业建设和国家法治建设添砖加瓦。</p>
          <img src="../assets/image/introduce-1.png" />
        </div>
      </div>
      <div class="box box-second">
        <div class="title">
          <p>梦想汇聚 绽放光芒</p>
          <p>方效拥有优秀的专业团队及人才梯队，覆盖多个专业领域</p>
        </div>
        <div class="content">
          <p>方效现有员工40多名。其中，执业律师大多数毕业于国内外知名法律院校，具备扎实的法律理论基础。另有部分律师曾在国内知名律师事务所，大型国有企业、民营企业等从事法律服务工作，拥有资深的行业经验。 </p>
          <p>此外，方效拥有优秀的专业团队及人才梯队，覆盖多个专业领域，尤其在破产清算与重整、争议解决、投融资、劳动等业务领域中具有丰富的法律服务经验。</p>
          <p> 建所以来，方效建立了一批以专业、高效特色为核心的律师专业服务团队，优化资源组合，充分发挥所内每位律师的专业特长，为客户提供专业、高效的法律服务。</p>
          <img src="../assets/image/introduce-2.png" />
        </div>
      </div>
      <div class="box">
        <div class="title">
          <p>成长赋能 “家”衍生</p>
          <p>让“家”文化渗透在律所的日常工作之中，力求律所与员工之间双向赋能，共同成长</p>
        </div>
        <div class="content">
          <p>方效高度重视员工的职业成长与发展，给予青年律师发展空间及平台。将人文关怀和对青年律师的扶持渗透至律所的各项制度及日常活动之中。让“家”文化渗透在律所的日常工作之中，力求律所与员工之间双向赋能，共同成长。 </p>
          <p>后续，方效将通过开展多样化、差异化的培训及丰富多彩的文体活动等，促进人才培养与发展，持续提升律所核心竞争力。</p>
          <img src="../assets/image/introduce-3.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'introduce',
}
</script>

<style lang="scss" scoped>
.introduce {
  .banner-wrap {
    background: url('../assets/image/introduce-banner.png');
    background-repeat: no-repeat;  // 不重复
    background-size: cover; // 铺满容器
  }
  .content-wrap {
    width: 100%;
    
    .box {
      padding: 40px calc((100vw - 1200px)/2);

      .title {
        border-top: 1px #666666 solid;
        border-bottom: 1px #666666 solid;
        padding: 45px 0;
        :first-child {
          font-size: 36px;
          font-weight: 700;
        }
        :last-child {
          font-size: 24px;
        }
      }
      .content {
        text-align: left;
        padding: 30px 0;
        font-size: 18px;
        p {
          text-indent: 2em; // 每段缩进2个字符
        }
        img {
          width: 100%;
          height: 240px;
        }
      }
    }
    .box-second {
      background: linear-gradient(360deg, rgba(0,0,0,0.08) 0%, rgba(100,100,100, 0.05) 100%);;
    }
  }
}
</style>